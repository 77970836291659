import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter, inject } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { FlagColor, FlagColorsService } from '../../../services/flagColors.service';
import { IntegrationAnswer, IntegrationsDialogComponent } from '../../../dialogs/integrations-dialog.component';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

@Component({
  selector: 'yesno-edit',
  templateUrl: './yesno-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoEditComponent implements OnInit {
  public readonly dialog = inject(MatDialog);
  private readonly flagColorsService = inject(FlagColorsService);
  private readonly utilitiesService = inject(UtilitiesService);
  @Input() question: any;
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() openTriggerDialog: ((initialId: string) => void) | undefined;

  public flagColors: FlagColor[] = [];

  ngOnInit(): void {
    this.flagColors = this.flagColorsService.getFlagColors();
    if (!this.question.offeredAnswers) {
      this.question.offeredAnswers = [
        {
          id: this.utilitiesService.getRandomAlphanumerics(32),
          value: 'No',
        },
        {
          id: this.utilitiesService.getRandomAlphanumerics(32),
          value: 'Yes',
        },
      ];
    }
    // make sure values are correct from switching questions
    if (
      this.question.offeredAnswers[0].value !== 'No' ||
      this.question.offeredAnswers[1].value !== 'Yes'
    ) {
      this.question.offeredAnswers[0].value = 'No';
      this.question.offeredAnswers[1].value = 'Yes';
    }
    if (this.question.offeredAnswers.length > 2) {
      this.question.offeredAnswers = [
        this.question.offeredAnswers[0],
        this.question.offeredAnswers[1],
      ];
    }
  }

  openIntegrationsDialog(answer: IntegrationAnswer): void {
    const dialogRef = this.dialog.open(IntegrationsDialogComponent);
    dialogRef.componentInstance.answer = answer;
  }
}
