// @ts-strict-ignore
import { inject, Injectable } from '@angular/core';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class NewSurveyService {
  private readonly utilitiesService = inject(UtilitiesService);

  createNewSurvey(): any {
    const date = new Date().toDateString();
    const newSurvey = {
      name: 'New - ' + date,
      id: this.utilitiesService.getRandomAlphanumerics(32),
      pages: [
        {
          id: this.utilitiesService.getRandomAlphanumerics(32),
          elements: [
            {
              id: this.utilitiesService.getRandomAlphanumerics(32),
              note: {
                location: 'HPI',
                qa: true,
              },
              orderNo: 1,
              type: 'question',
              question: {
                id: this.utilitiesService.getRandomAlphanumerics(32),
                text: '',
                type: 'text',
                required: false,
              },
            },
          ],
        },
      ],
    }; // end newSurvey variable
    return newSurvey;
  } // end duplicate survey function
}
