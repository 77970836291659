// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  inject,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

@Component({
  selector: 'priority-edit',
  templateUrl: './priority-edit.component.html',
  styleUrls: ['./priority-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityEditComponent implements OnInit {
  private readonly utilitiesService = inject(UtilitiesService);

  @Input() question: any;
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.question.priorityList) {
      this.question.priorityList = [];
      this.addOption(0);
      this.addOption(0);
    }
  }

  addOption(index: number): void {
    const id = this.utilitiesService.getRandomAlphanumerics(32);
    this.question.priorityList.splice(index, 0, {
      id,
      orderNo: index + 1,
      value: '',
    });
    this.updateQuestion();
  }

  drop(event: CdkDragDrop<void>) {
    moveItemInArray(
      this.question.priorityList,
      event.previousIndex,
      event.currentIndex
    );
    this.updateQuestion();
  }

  onAnswerChange(index: number, newValue: string): void {
    this.question.priorityList[index].value = newValue;
    this.updateQuestion();
  }

  updateQuestion(): void {
    this.question.priorityList.forEach((item, index) => {
      item.orderNo = index;
    });
    this.questionChange.emit(this.question);
  }
}
