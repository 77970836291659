// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit, inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatDialog } from '@angular/material/dialog';
import { FlagColorsService } from '../../../services/flagColors.service';
import { IntegrationsDialogComponent } from '../../../dialogs/integrations-dialog.component';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

@Component({
  selector: 'radio-checkbox-select-edit',
  templateUrl: './radio-checkbox-select-edit.component.html',
  styleUrls: ['./radio-checkbox-select-edit.component.scss'],
  providers: [FlagColorsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioCheckboxSelectEditComponent implements OnInit {
  public readonly dialog = inject(MatDialog);
  private readonly flagColorsService = inject(FlagColorsService);
  private readonly utilitiesService = inject(UtilitiesService);
  @Input() question: any;
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isActive: boolean;

  @Output() onTriggerDialogClicked: EventEmitter<string> = new EventEmitter<
    string
  >(); // Emits the id of the answer

  @Input() openTriggerDialog: (initialId: string) => void;

  public get flagColors() {
    return this.flagColorsService.getFlagColors();
  }

  ngOnInit() {
    if (!this.question.offeredAnswers) {
      this.question.offeredAnswers = [];
      // Add two options when you first make a new question
      this.addOption(0);
      this.addOption(0);
    }
    this.question.offeredAnswers.forEach((answer) => {
      if (answer.pageFlow === undefined || answer.pageFlow === null) {
        answer.pageFlow = {};
      }
      if (
        answer.pageFlow.trigger === undefined ||
        answer.pageFlow.trigger === null
      ) {
        answer.pageFlow.trigger = [];
      }
    });
    this.updateQuestion();
  }

  openIntegrationsDialog(answer) {
    const dialogRef = this.dialog.open(IntegrationsDialogComponent);
    dialogRef.componentInstance.answer = answer;
    dialogRef.componentInstance.startingText = answer.value;
  }

  addOption(index: number) {
    const id = this.utilitiesService.getRandomAlphanumerics(32);
    this.question.offeredAnswers.splice(index, 0, {
      id,
      orderNo: index + 1,
      value: '',
    });
    this.updateQuestion();
  }

  drop(event: CdkDragDrop<void>) {
    moveItemInArray(
      this.question.offeredAnswers,
      event.previousIndex,
      event.currentIndex
    );
    this.updateQuestion();
  }

  toggleOtherAnswer() {
    this.question.otherAnswer = !this.question.otherAnswer;
    this.updateQuestion();
  }

  onAnswerChange(index, newValue) {
    this.question.offeredAnswers[index].value = newValue;
    this.updateQuestion();
  }

  updateQuestion() {
    this.question.offeredAnswers.forEach((item, index) => {
      item.orderNo = index;
    });
    this.questionChange.emit(this.question);
  }
}
