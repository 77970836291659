import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  private static readonly LOWER_CASE_LETTERS = 'abcdefghijklmnopqrstuvwxyz'.split('');
  private static readonly UPPER_CASE_LETTERS = UtilitiesService.LOWER_CASE_LETTERS.map((letter) => letter.toUpperCase());
  private static readonly DIGITS = '0123456789'.split('');

  public getRandomLetters(length: number): string {
    return this.getRandomCharacters([
      ...UtilitiesService.LOWER_CASE_LETTERS,
      ...UtilitiesService.UPPER_CASE_LETTERS
    ], length);
  }

  public getRandomAlphanumerics(length: number): string {
    return this.getRandomCharacters([
      ...UtilitiesService.LOWER_CASE_LETTERS,
      ...UtilitiesService.UPPER_CASE_LETTERS,
      ...UtilitiesService.DIGITS
    ], length);
  }

  /**
   * Checks if a string is empty or only contains whitespace
   * We need to handle null or undefined values for legacy code with no strict null checks
   */
  public isEmptyString(value: string | null | undefined): boolean {
    return !value || value.trim().length === 0;
  }

  private getRandomCharacters(characterBank: string[], length: number): string {
    if (length <= 0 || !Number.isInteger(length)) {
      return '';
    }
    let returnValue = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characterBank.length);
      returnValue += characterBank.slice(randomIndex, randomIndex + 1);
    }
    return returnValue;
  }
}
