import { Component, ElementRef, HostBinding, Input, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RegexService } from '@insig-health/services/regex/regex.service';

@Component({
  selector: 'insig-ui-one-time-password-input',
  templateUrl: './insig-one-time-password-input.component.html',
  styleUrls: ['./insig-one-time-password-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: InsigOneTimePasswordInputComponent,
  }],
})
export class InsigOneTimePasswordInputComponent implements ControlValueAccessor {
  private readonly elementRef = inject(ElementRef);
  @HostBinding('attr.autofocus')
  @Input() autofocus: unknown | null = null;
  
  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public value = '';

  ngAfterViewInit(): void {
    if (this.autofocus !== null) {
      const input = this.elementRef.nativeElement.querySelector('input') as HTMLInputElement;
      input.focus();
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  handleChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    target.value = target.value.replace(RegexService.NON_DIGIT_GLOBAL_REGEX, '');
    this.onChange?.(target.value);
  }

  handleBlur(): void {
    this.onTouched?.();
  }
}
