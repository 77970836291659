/**
 * Insig Doctor Management APIs
 * The specification contains Insig Doctor APIs
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DoctorManagementErrorResponse { 
    errorCode?: DoctorManagementErrorResponse.ErrorCodeEnum;
    errorMessage?: string;
}
export namespace DoctorManagementErrorResponse {
    export type ErrorCodeEnum = 'account-already-exists' | 'password-policy-failure' | 'required-field-missing' | 'other-error';
    export const ErrorCodeEnum = {
        AccountAlreadyExists: 'account-already-exists' as ErrorCodeEnum,
        PasswordPolicyFailure: 'password-policy-failure' as ErrorCodeEnum,
        RequiredFieldMissing: 'required-field-missing' as ErrorCodeEnum,
        OtherError: 'other-error' as ErrorCodeEnum
    };
}


