import { Injectable } from '@angular/core';

export interface FlagColor {
  name: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class FlagColorsService {
  private flagColors = [
    { name: 'Red', color: '#CF3759' },
    { name: 'Orange', color: '#E77547' },
    { name: 'Yellow', color: '#ffd600' },
    { name: 'Green', color: '#43a047' },
    { name: 'Blue', color: 'var(--primary-color)' },
    { name: 'Purple', color: '#b388ff' },
  ];

  getFlagColors(): FlagColor[] {
    return this.flagColors;
  }
}
