// @ts-ignore
import { InsigBookingSite } from '@insig-health/config/config';
import { version } from './projectProperties';

export const environment = {
  production: true,
  hmr: false,
  branch: 'production',
  inactivityTimeoutMs: 1000 * 60 * 60,
  stripeKey: 'pk_live_9WokTriA8wKYyADx7ZrgTQxQ',
  stripeClientId: 'ca_AMncGOos0gZ3yhfPWKmdLhjmJIJqme3i',
  cloudFunctionsEndpoint: 'https://us-central1-insig-ca.cloudfunctions.net/',
  version,
  firebaseConfig: {
    apiKey: 'AIzaSyB9e1iPCy9av-XjRK2Kp9RPjgMWJZTMqBY',
    authDomain: 'insig-ca.firebaseapp.com',
    databaseURL: 'https://insig-ca.firebaseio.com',
    projectId: 'insig-ca',
    storageBucket: 'insig-ca.appspot.com',
    messagingSenderId: '47931142088',
    appId: '1:47931142088:web:1a2314589268f1a0fcdafd',
  },
  appUrl: {
    default: 'https://app.insighealth.com/',
    map: [
      {
        origin: 'app.well.company',
        appUrl: 'https://app.wellclinics.ca/',
      },
      {
        origin: 'app.wellclinics.ca',
        appUrl: 'https://app.wellclinics.ca/',
      },
      {
        origin: 'app.jacknathanhealth.com',
        appUrl: 'https://app.jacknathanhealth.com/',
      },
      {
        origin: 'app.thevirtualdoctor.org',
        appUrl: 'https://app.thevirtualdoctor.org/',
      },
      {
        origin: 'virtual.highmark.tech',
        appUrl: 'https://virtual.highmark.tech/',
      },
      {
        origin: 'app.tiahealth.com',
        appUrl: 'https://app.tiahealth.com/',
      },
      {
        origin: 'app.yourcare.health',
        appUrl: 'https://app.yourcare.health/',
      },
      {
        origin: 'beta1.insighealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
      {
        origin: 'beta.tiahealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
      {
        origin: 'book-beta.insighealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
      {
        origin: 'book-beta.tiahealth.com',
        appUrl: 'https://beta.tiahealth.com/',
      },
    ],
  },
  awsCloudFunctionsEndpoint: {
    default: 'https://api.goinsig.com/',
    map: [
      {
        origin: 'beta1.insighealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
      {
        origin: 'beta.tiahealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
      {
        origin: 'book-beta.insighealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
      {
        origin: 'book-beta.tiahealth.com',
        awsCloudFunctionsEndpoint: 'https://beta.api.goinsig.com/',
      },
    ],
  },
  javaBackendEndpoint: {
    default: 'https://services-prod.insighealth.com/',
    map: [
      {
        origin: 'beta1.insighealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
      {
        origin: 'beta.tiahealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
      {
        origin: 'book-beta.insighealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
      {
        origin: 'book-beta.tiahealth.com',
        javaBackendEndpoint: 'https://services-beta.goinsig.com/',
      },
    ],
  },
  newBookingFlowUrl: {
    default: 'https://book.tiahealth.com',
    map: [
      {
        origin: 'beta1.insighealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
      {
        origin: 'beta.tiahealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
      {
        origin: 'book-beta.insighealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
      {
        origin: 'book-beta.tiahealth.com',
        newBookingFlowUrl: 'https://book-beta.tiahealth.com',
      },
    ],
  },
  insigBookingTheme: {
    map: [],
  },
  insigBookingSite: {
    default: InsigBookingSite.TIA,
    map: [],
  },
  insigCoreSite: {
    default: 'https://app.insighealth.com/app/virtual/dashboard',
    map: [
      {
        origin: 'book-beta.insighealth.com',
        site: 'https://beta.tiahealth.com/app/virtual/dashboard',
      },
      {
        origin: 'book-beta.tiahealth.com',
        site: 'https://beta.tiahealth.com/app/virtual/dashboard',
      },
    ],
  },
  awsWafCaptchaApiKey: 'cKLx1pbv9pJ6ixe2cDiTBBEKITfO6OTP0OObwU5GdBG/HMI/CZnhAr2e8j9gd7yu3CmeoW4JXtCmHqrYEKIQ8m0AeBrElnGm+/KZxeI4rOIdfTfg84DxxiuuA/tyMWA+vza3VSgW2gBEFyuD1dJG9e6+ElCp+U4NpCa53x2bGCmAj8n8pDv/17oNqJ1BzTEPhyg08LrRb1Vqije1T0ssTqeB9JAyWfQUw20MTLOUL3L/GAlo5cQ8l3Hi+lGX/f142Pp+uMkxLncEakhQx/jtkUV0Zk/qSyVGYJ6jAo8uHuxqERMf2VJ9TLZnB/+86grIEUybm5OKcpLn8ZvRXHBL8sxdP8WCL9RZIF+eYCGx3Z4FhwAsIwQwabs/GnEM2tKZHU6NZsesvWJfVUM5UibIAbQf7O862Ltx7WddJsVD6TW5yZCBHKSFpFft2ozTRFWExGaUZTSkfEz2jWiOC8fVpeoRdMLjQF5LmXV60sVzzxor6+3LNA8narF887iUJPN+GzKl+RwPGGCaj3Tf01R3o4CZrow6DS98w4BuP3nGAd/Mmeubvwk8oWDiWFp7LvyqPUbTIMup0HKQIFMxXaiLsc1mRf35ZwyEv5LXsloFf4yc8cnCJ9TBH35NX9KG4avb4eq10fgpg5ttLH9su//E98SwyoDNagboGUol3h+zmLs=_0_1',
  awsWafCaptchaScriptUrl: 'https://d8a031c1915a.ca-central-1.captcha-sdk.awswaf.com/d8a031c1915a/jsapi.js',
  pdfBasePath: 'https://express.goinsig.com/s3/insig-filled-pdfs/getPdf/',
  taliAiScriptUrl: 'https://sdk.tali.ai/tali.js',
};
