// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';

export interface ShortCode {
  shortcode: string;
  cid: string;
  pharmacyName?: string;
  pharmacyFax?: string;
  pharmacyAddress?: string;
  geolocatePharmacy?: any;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private readonly apollo = inject(Apollo);
  private readonly firestoreService = inject(FirestoreService);
  // graphql queries
  private setShortcodeMutation = gql`
    mutation SetShortcodeMutation(
      $companyID: ID!
      $shortcode: String!
      $token: ID!
    ) {
      setShortcode(cid: $companyID, shortcode: $shortcode, token: $token)
    }
  `;
  //

  getShortcodeByShortcode(shortcode: string): Observable<ShortCode> {
    if (!shortcode) {
      shortcode = '';
    }
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('shortcodes')
        .doc(shortcode)
        .onSnapshot({
          next: (snapshot) => {
            if (snapshot.data()) {
              observer.next(snapshot.data() as ShortCode);
            } else {
              observer.next(undefined);
            }
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
        });
    });
  }

  getAvailableSpecialties(): Observable<any> {
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('availableSpecialties')
        .onSnapshot({
          next: (snapshot) => {
            observer.next(snapshot.docs.map((doc) => doc.data()));
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
        });
    });
  }

  getShortcodeFromCompanyId(cid: string): Observable<string> {
    return Observable.create((observer) => {
      return firebase
        .firestore()
        .collection('shortcodes')
        .where('cid', '==', cid)
        .onSnapshot({
          next: (snapshot) => {
            if (snapshot.docs[0]) {
              observer.next(snapshot.docs[0].id);
            } else {
              observer.next(undefined);
            }
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
        });
    });
  }

  saveCompanyIdToShortcode(
    cid: string,
    shortcode: string,
    token: string
  ): Promise<any> {
    if (!cid || !shortcode) {
      throw new Error('parameters cannot be undefined');
    }
    console.log('saving shortcode');
    return this.apollo
      .mutate({
        mutation: this.setShortcodeMutation,
        variables: {
          companyID: cid,
          shortcode,
          token,
        },
      })
      .toPromise()
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getEMRList(countryCode: string) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('supportedEMRs')
        .where('country', '==', countryCode)
    );
  }
}
