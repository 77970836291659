// @ts-strict-ignore
import { Component, Input, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatDialog } from '@angular/material/dialog';
import { FlagColorsService } from '../../../services/flagColors.service';
import { IntegrationsDialogComponent } from '../../../dialogs/integrations-dialog.component';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

@Component({
  selector: 'scoring-edit',
  templateUrl: './scoring-edit.component.html',
  styleUrls: ['./scoring-edit.component.scss'],
})
export class ScoringEditComponent implements OnInit {
  private readonly flagColorsService = inject(FlagColorsService);
  private readonly utilitiesService = inject(UtilitiesService);
  public readonly dialog = inject(MatDialog);
  @Input() question: any;
  @Output() questionChange: EventEmitter<any>;
  public flagColors = null;

  constructor(
) {
    this.questionChange = new EventEmitter<any>();
  }

  ngOnInit() {
    this.flagColors = this.flagColorsService.getFlagColors();
    if (!this.question.offeredAnswers) {
      this.question.offeredAnswers = [];
      this.addOption(0);
      this.addOption(0);
    }
  }

  openIntegrationsDialog(answer) {
    const dialogRef = this.dialog.open(IntegrationsDialogComponent);
    dialogRef.componentInstance.answer = answer;
    dialogRef.componentInstance.startingText = answer.value;
  }

  addOption(index: number) {
    const id = this.utilitiesService.getRandomAlphanumerics(32);
    this.question.offeredAnswers.splice(index, 0, {
      id,
      orderNo: index + 1,
      score: '',
      value: '',
    });
    this.updateQuestion();
  }

  drop(event: CdkDragDrop<void>) {
    moveItemInArray(
      this.question.offeredAnswers,
      event.previousIndex,
      event.currentIndex
    );
    this.updateQuestion();
  }

  onAnswerChange(index, newValue) {
    this.question.offeredAnswers[index].value = newValue;
    this.updateQuestion();
  }

  onScoreChange(index, newValue) {
    this.question.offeredAnswers[index].score = newValue;
    this.updateQuestion();
  }

  updateQuestion() {
    this.question.offeredAnswers.forEach((item, index) => {
      item.orderNo = index;
    });
    this.questionChange.emit(this.question);
  }
}
