// @ts-strict-ignore
import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

@Component({
  selector: 'trigger-dialog',
  templateUrl: 'trigger-dialog.component.html',
})
export class TriggerDialogComponent implements OnInit {
  public readonly data = inject<any>(MAT_DIALOG_DATA);
  public readonly dialogRef = inject<MatDialogRef<TriggerDialogComponent>>(MatDialogRef<TriggerDialogComponent>);
  private readonly utilitiesService = inject(UtilitiesService);
  private question: any;
  private element: any;
  private page: any;
  public survey: any;
  private optionList: any;
  private optionId: string;
  private triggerList: any;
  private elementList: any;
  private elementDict: any;
  private searchFilter = '';
  private filteredList: any;

  ngOnInit() {
    this.question = this.data.element.question;
    if (this.question.type === 'multiyesno') {
      this.optionList = this.question.offeredQuestions;
    } else {
      this.optionList = this.question.offeredAnswers;
    }
    // Initialize trigger arrays
    for (const option in this.optionList) {
      if (this.optionList[option]) {
        if (this.optionList[option].pageFlow === undefined) {
          this.optionList[option].pageFlow = {};
        }
        if (this.optionList[option].pageFlow.trigger === undefined) {
          this.optionList[option].pageFlow.trigger =
            this.question.type === 'multiyesno' ? { Yes: [], No: [] } : [];
        }
      }
    }
    this.optionId = this.data.initialId;
    this.triggerList = this.optionList[
      this.getOptionIndex(this.optionId)
    ].pageFlow.trigger;
    this.element = this.data.element;
    this.survey = this.data.survey;
    this.generateElementDictAndList();
    for (const page of this.survey.pages) {
      for (const element of page.elements) {
        if (element.id === this.element.id) {
          this.page = page;
          break;
        }
      }
      if (this.page) {
        break;
      }
    }
  }

  filterList(filter?: string) {
    if (!(filter === undefined)) {
      this.searchFilter = filter;
    }
    this.filteredList = [];
    for (const item of this.elementList) {
      if (
        !(
          item.text.toLowerCase().indexOf(this.searchFilter.toLowerCase()) ===
          -1
        )
      ) {
        this.filteredList.push(item);
      }
    }
  }

  generateElementDictAndList(): any {
    // Generates a dict of element names with id as key; also adds them to a list
    // TODO add support for paragraphs (only does questions right now)
    this.elementDict = {};
    this.elementList = [];
    for (const page of this.survey.pages) {
      for (const element of page.elements) {
        if (element.question) {
          this.elementDict[element.id] = element.question.text;
          this.elementList.push({
            id: element.id,
            text: element.question.text,
          });
        } else if (element.paragraph) {
          const strippedParagraph = element.paragraph.html.replace(
            new RegExp(/<.*?>/, 'g'),
            ''
          );
          let text = 'Paragraph: ' + strippedParagraph.substring(0, 40);
          if (strippedParagraph !== strippedParagraph.substring(0, 40)) {
            text += '...';
          }
          this.elementDict[element.id] = text;
          this.elementList.push({
            id: element.id,
            text,
          });
        }
      }
    }
    this.filterList();
  }

  changeOption(event) {
    // Save local changes to the current list
    this.optionList[
      this.getOptionIndex(this.optionId)
    ].pageFlow.trigger = this.triggerList;
    // Load values of requested option
    this.optionId = event;
    this.triggerList = this.optionList[
      this.getOptionIndex(this.optionId)
    ].pageFlow.trigger;
  }

  getOptionIndex(optionId: string, multiyesno = false): number {
    for (let i = 0; i < this.optionList.length; i++) {
      const option = this.optionList[i];
      if (option.id === optionId) {
        if (option.pageFlow === undefined) {
          option.pageFlow = {
            trigger: multiyesno ? [] : { Yes: [], No: [] },
          };
        }
        return i;
      }
    }
    return -1;
  }

  addNewSubquestion(index: number) {
    // Adds a new subquestion to the current option and sets the triggers
    // Initialize the new element
    const newElement = {
      id: this.utilitiesService.getRandomAlphanumerics(32),
      orderNo: index + 1,
      question: {
        id: this.utilitiesService.getRandomAlphanumerics(32),
        required: false,
        text: '',
        type: 'text',
      },
      type: 'question',
      doctor: this.element.doctor,
      pageFlow: {
        child: true,
        parents: [this.element.id],
      },
    };
    // Advance the orderNo of subsequent elements
    for (let i = index; i < this.page.elements.length; i++) {
      this.page.elements[i].orderNo++;
    }

    this.page.elements.splice(index, 0, newElement);
    this.addTrigger(newElement.id);
    this.generateElementDictAndList();
  }

  addTrigger(id: string) {
    // Adds a trigger to the current option to an existing question by id
    console.log(this.question.type);
    if (this.question.type === 'multiyesno') {
      if (this.data.yesNoOption === 'No') {
        this.triggerList['No']
          ? this.triggerList['No'].push(id)
          : (this.triggerList['No'] = [id]);
      } else {
        this.triggerList['Yes']
          ? this.triggerList['Yes'].push(id)
          : (this.triggerList['Yes'] = [id]);
      }
    } else {
      this.triggerList.push(id);
    }
    this.updateChildren();
    this.addParentToChild(id, this.element.id);
  }

  removeTrigger(index: number) {
    // Removes the trigger from the current option at the specified index
    let id;
    if (this.question.type === 'multiyesno') {
      if (this.data.yesNoOption === 'No') {
        id = this.triggerList['No'][index];
        this.triggerList['No'].splice(index, 1);
      } else {
        id = this.triggerList['Yes'][index];
        this.triggerList['Yes'].splice(index, 1);
      }
    } else {
      id = this.triggerList[index];
      this.triggerList.splice(index, 1);
    }
    this.updateChildren();

    // If no other option in this parent triggers this child, remove the relationship
    if (this.element.pageFlow.children.indexOf(id) === -1) {
      this.removeParentFromChild(id, this.element.id);
    }
  }

  addParentToChild(childID: string, parentID: string) {
    // Find the child element
    for (const page of this.survey.pages) {
      for (const element of page.elements) {
        if (element.id === childID) {
          // Add the parent to the child element
          if (element.pageFlow === undefined) {
            element.pageFlow = {
              parents: [],
            };
          }
          if (element.pageFlow.parents === undefined) {
            element.pageFlow.parents = [];
          }
          if (element.pageFlow.parents.indexOf(parentID) === -1) {
            element.pageFlow.parents.push(parentID);
          }
          element.pageFlow.child = true;
          return; // Do not iterate any further
        }
      }
    }
  }

  /**
   * Removes a parent association with a child, if the association exists
   * @param {string} childID  The id of the child element
   * @param {string} parentID The id of the parent element
   */
  removeParentFromChild(childID: string, parentID: string): void {
    // Find the child element
    for (const page of this.survey.pages) {
      for (const element of page.elements) {
        if (element.id === childID) {
          // Remove the parent from the child element
          if (element.pageFlow === undefined) {
            return; // Do nothing
          } else {
            const parentIndex = element.pageFlow.parents.indexOf(parentID);
            if (parentIndex !== -1) {
              // If the parent is found
              element.pageFlow.parents.splice(parentIndex, 1);
              if (element.pageFlow.parents.length === 0) {
                element.pageFlow.child = false;
              }
            }
          }
          return; // Do not iterate any further
        }
      }
    }
  }

  updateChildren() {
    // Updates pageFlow.children()
    const newChildren = [];
    for (const option of this.optionList) {
      if (option.pageFlow === undefined) {
        option.pageFlow = {
          trigger: [],
        };
      }
      if (this.question.type === 'multiyesno') {
        if (option.pageFlow.trigger['No']) {
          for (const trigger of option.pageFlow.trigger['No']) {
            if (newChildren.indexOf(trigger) === -1) {
              newChildren.push(trigger);
            }
          }
        }
        if (option.pageFlow.trigger['Yes']) {
          for (const trigger of option.pageFlow.trigger['Yes']) {
            if (newChildren.indexOf(trigger) === -1) {
              newChildren.push(trigger);
            }
          }
        }
      } else {
        for (const trigger of option.pageFlow.trigger) {
          if (newChildren.indexOf(trigger) === -1) {
            newChildren.push(trigger);
          }
        }
      }
    }
    if (this.element.pageFlow === undefined) {
      this.element.pageFlow = {};
    }
    this.element.pageFlow.children = newChildren;
    if (newChildren.length === 0) {
      this.element.pageFlow.parent = false;
    } else {
      this.element.pageFlow.parent = true;
    }
  }

  // Toggles whether a subquestion is triggered by the current option
  // If toggleValue is true then add a trigger; if it is false remove the trigger
  toggleTrigger(toggleValue: boolean, subquestionID: string) {
    const triggerList =
      this.question.type === 'multiyesno'
        ? this.triggerList[this.data.yesNoOption]
        : this.triggerList;

    if (toggleValue && triggerList.indexOf(subquestionID) === -1) {
      this.addTrigger(subquestionID);
    }
    if (!toggleValue) {
      const index = triggerList.indexOf(subquestionID);
      if (!(index === -1)) {
        this.removeTrigger(index);
      }
    }
  }
}
