/**
 * Insig Doctor Management APIs
 * The specification contains Insig Doctor APIs
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScheduleDay } from './scheduleDay';


export interface DoctorWeeklySchedule { 
    /**
     * One of: virtual, clinic, or both
     */
    defaultAvailability: DoctorWeeklySchedule.DefaultAvailabilityEnum;
    sunday: ScheduleDay;
    monday: ScheduleDay;
    tuesday: ScheduleDay;
    wednesday: ScheduleDay;
    thursday: ScheduleDay;
    friday: ScheduleDay;
    saturday: ScheduleDay;
}
export namespace DoctorWeeklySchedule {
    export type DefaultAvailabilityEnum = 'VIRTUAL' | 'CLINIC' | 'BOTH';
    export const DefaultAvailabilityEnum = {
        Virtual: 'VIRTUAL' as DefaultAvailabilityEnum,
        Clinic: 'CLINIC' as DefaultAvailabilityEnum,
        Both: 'BOTH' as DefaultAvailabilityEnum
    };
}


