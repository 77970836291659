/**
 * Insig Doctor Management APIs
 * The specification contains Insig Doctor APIs
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TimeSegment { 
    /**
     * One of: virtual, clinic, both or default
     */
    availability: TimeSegment.AvailabilityEnum;
    /**
     * Milliseconds past midnight of the start of the time segment in the operating time zone
     */
    operatingStartTime: number;
    /**
     * Milliseconds past midnight of the end of the time segment in the operating time zone
     */
    operatingEndTime: number;
    /**
     * Location id of the clinic if availability includes in person values
     */
    location?: string;
}
export namespace TimeSegment {
    export type AvailabilityEnum = 'VIRTUAL' | 'CLINIC' | 'BOTH' | 'DEFAULT';
    export const AvailabilityEnum = {
        Virtual: 'VIRTUAL' as AvailabilityEnum,
        Clinic: 'CLINIC' as AvailabilityEnum,
        Both: 'BOTH' as AvailabilityEnum,
        Default: 'DEFAULT' as AvailabilityEnum
    };
}


