/**
 * Insig Appointment Management APIs
 * This specification contains Insig Appointment APIs
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@insighealth.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AppointmentUpdateRequest } from '../model/appointmentUpdateRequest';
// @ts-ignore
import { CombinedEstimatedWaitTimeResponse } from '../model/combinedEstimatedWaitTimeResponse';
// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { PatientAppointmentResponse } from '../model/patientAppointmentResponse';
// @ts-ignore
import { SuccessResponse } from '../model/successResponse';
// @ts-ignore
import { TwilioVideoLogReportRequest } from '../model/twilioVideoLogReportRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AppointmentManagementService {

    protected basePath = 'http://localhost:8000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get the detailed estimated wait time for the doctor to join the appointment
     * Get the detailed estimated wait time for the doctor to join the appointment
     * @param appointmentLinkId Appointment Link ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppointmentEstimatedWaitTime(appointmentLinkId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CombinedEstimatedWaitTimeResponse>;
    public getAppointmentEstimatedWaitTime(appointmentLinkId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CombinedEstimatedWaitTimeResponse>>;
    public getAppointmentEstimatedWaitTime(appointmentLinkId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CombinedEstimatedWaitTimeResponse>>;
    public getAppointmentEstimatedWaitTime(appointmentLinkId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (appointmentLinkId === null || appointmentLinkId === undefined) {
            throw new Error('Required parameter appointmentLinkId was null or undefined when calling getAppointmentEstimatedWaitTime.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (insigInternalToken) required
        localVarCredential = this.configuration.lookupCredential('insigInternalToken');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-API-KEY', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/appointment/${this.configuration.encodeParam({name: "appointmentLinkId", value: appointmentLinkId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/detailedWaitTime/`;
        return this.httpClient.get<CombinedEstimatedWaitTimeResponse>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the estimated wait time for the doctor to join the appointment
     * Get the estimated wait time for the doctor to join the appointment
     * @param patientId Patient Id
     * @param appointmentLinkId Appointment Link ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppointmentWaitTimeMessage(patientId: string, appointmentLinkId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json', context?: HttpContext}): Observable<string>;
    public getAppointmentWaitTimeMessage(patientId: string, appointmentLinkId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public getAppointmentWaitTimeMessage(patientId: string, appointmentLinkId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public getAppointmentWaitTimeMessage(patientId: string, appointmentLinkId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling getAppointmentWaitTimeMessage.');
        }
        if (appointmentLinkId === null || appointmentLinkId === undefined) {
            throw new Error('Required parameter appointmentLinkId was null or undefined when calling getAppointmentWaitTimeMessage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/appointment/${this.configuration.encodeParam({name: "appointmentLinkId", value: appointmentLinkId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/waitTimeMessage/`;
        return this.httpClient.get<string>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get appointment for a patient
     * Get appointment for a patient
     * @param patientId Patient ID
     * @param appointmentDocumentId Appointment Document ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPatientAppointmentByDocumentId(patientId: string, appointmentDocumentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PatientAppointmentResponse>;
    public getPatientAppointmentByDocumentId(patientId: string, appointmentDocumentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PatientAppointmentResponse>>;
    public getPatientAppointmentByDocumentId(patientId: string, appointmentDocumentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PatientAppointmentResponse>>;
    public getPatientAppointmentByDocumentId(patientId: string, appointmentDocumentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling getPatientAppointmentByDocumentId.');
        }
        if (appointmentDocumentId === null || appointmentDocumentId === undefined) {
            throw new Error('Required parameter appointmentDocumentId was null or undefined when calling getPatientAppointmentByDocumentId.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/patient/${this.configuration.encodeParam({name: "patientId", value: patientId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/appointment/${this.configuration.encodeParam({name: "appointmentDocumentId", value: appointmentDocumentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.get<PatientAppointmentResponse>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Twilio phone call status callback
     * Twilio phone call status callback
     * @param callSid 
     * @param accountSid 
     * @param from Caller\\\&#39;s phone number in E.164 format
     * @param to Called phone number in E.164 format
     * @param callStatus 
     * @param apiVersion 
     * @param direction 
     * @param forwardedFrom 
     * @param callerName 
     * @param parentCallSid 
     * @param callToken 
     * @param callDuration Call duration in seconds
     * @param answeredBy See https://www.twilio.com/docs/voice/answering-machine-detection#webhook-parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public twilioPhoneCallStatusCallback(callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, callerName?: string, parentCallSid?: string, callToken?: string, callDuration?: string, answeredBy?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SuccessResponse>;
    public twilioPhoneCallStatusCallback(callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, callerName?: string, parentCallSid?: string, callToken?: string, callDuration?: string, answeredBy?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SuccessResponse>>;
    public twilioPhoneCallStatusCallback(callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, callerName?: string, parentCallSid?: string, callToken?: string, callDuration?: string, answeredBy?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SuccessResponse>>;
    public twilioPhoneCallStatusCallback(callSid?: string, accountSid?: string, from?: string, to?: string, callStatus?: string, apiVersion?: string, direction?: string, forwardedFrom?: string, callerName?: string, parentCallSid?: string, callToken?: string, callDuration?: string, answeredBy?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (callSid !== undefined) {
            localVarFormParams = localVarFormParams.append('CallSid', <any>callSid) as any || localVarFormParams;
        }
        if (accountSid !== undefined) {
            localVarFormParams = localVarFormParams.append('AccountSid', <any>accountSid) as any || localVarFormParams;
        }
        if (from !== undefined) {
            localVarFormParams = localVarFormParams.append('From', <any>from) as any || localVarFormParams;
        }
        if (to !== undefined) {
            localVarFormParams = localVarFormParams.append('To', <any>to) as any || localVarFormParams;
        }
        if (callStatus !== undefined) {
            localVarFormParams = localVarFormParams.append('CallStatus', <any>callStatus) as any || localVarFormParams;
        }
        if (apiVersion !== undefined) {
            localVarFormParams = localVarFormParams.append('ApiVersion', <any>apiVersion) as any || localVarFormParams;
        }
        if (direction !== undefined) {
            localVarFormParams = localVarFormParams.append('Direction', <any>direction) as any || localVarFormParams;
        }
        if (forwardedFrom !== undefined) {
            localVarFormParams = localVarFormParams.append('ForwardedFrom', <any>forwardedFrom) as any || localVarFormParams;
        }
        if (callerName !== undefined) {
            localVarFormParams = localVarFormParams.append('CallerName', <any>callerName) as any || localVarFormParams;
        }
        if (parentCallSid !== undefined) {
            localVarFormParams = localVarFormParams.append('ParentCallSid', <any>parentCallSid) as any || localVarFormParams;
        }
        if (callToken !== undefined) {
            localVarFormParams = localVarFormParams.append('CallToken', <any>callToken) as any || localVarFormParams;
        }
        if (callDuration !== undefined) {
            localVarFormParams = localVarFormParams.append('CallDuration', <any>callDuration) as any || localVarFormParams;
        }
        if (answeredBy !== undefined) {
            localVarFormParams = localVarFormParams.append('AnsweredBy', <any>answeredBy) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/twilio/webhook/phone/call-status-callback/`;
        return this.httpClient.post<SuccessResponse>(`${this.configuration.basePath}${localVarPath}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Twilio video room status callback
     * Twilio video room status callback
     * @param accountSid 
     * @param roomName 
     * @param roomSid 
     * @param roomStatus 
     * @param roomType 
     * @param statusCallbackEvent 
     * @param timestamp Timestamp in UTC ISO 8601 format
     * @param participantSid 
     * @param participantStatus 
     * @param participantDuration 
     * @param participantIdentity 
     * @param roomDuration 
     * @param sequenceNumber 
     * @param trackSid 
     * @param trackKind 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public twilioRoomStatusCallback(accountSid?: string, roomName?: string, roomSid?: string, roomStatus?: string, roomType?: string, statusCallbackEvent?: string, timestamp?: string, participantSid?: string, participantStatus?: string, participantDuration?: string, participantIdentity?: string, roomDuration?: string, sequenceNumber?: string, trackSid?: string, trackKind?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SuccessResponse>;
    public twilioRoomStatusCallback(accountSid?: string, roomName?: string, roomSid?: string, roomStatus?: string, roomType?: string, statusCallbackEvent?: string, timestamp?: string, participantSid?: string, participantStatus?: string, participantDuration?: string, participantIdentity?: string, roomDuration?: string, sequenceNumber?: string, trackSid?: string, trackKind?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SuccessResponse>>;
    public twilioRoomStatusCallback(accountSid?: string, roomName?: string, roomSid?: string, roomStatus?: string, roomType?: string, statusCallbackEvent?: string, timestamp?: string, participantSid?: string, participantStatus?: string, participantDuration?: string, participantIdentity?: string, roomDuration?: string, sequenceNumber?: string, trackSid?: string, trackKind?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SuccessResponse>>;
    public twilioRoomStatusCallback(accountSid?: string, roomName?: string, roomSid?: string, roomStatus?: string, roomType?: string, statusCallbackEvent?: string, timestamp?: string, participantSid?: string, participantStatus?: string, participantDuration?: string, participantIdentity?: string, roomDuration?: string, sequenceNumber?: string, trackSid?: string, trackKind?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (accountSid !== undefined) {
            localVarFormParams = localVarFormParams.append('AccountSid', <any>accountSid) as any || localVarFormParams;
        }
        if (roomName !== undefined) {
            localVarFormParams = localVarFormParams.append('RoomName', <any>roomName) as any || localVarFormParams;
        }
        if (roomSid !== undefined) {
            localVarFormParams = localVarFormParams.append('RoomSid', <any>roomSid) as any || localVarFormParams;
        }
        if (roomStatus !== undefined) {
            localVarFormParams = localVarFormParams.append('RoomStatus', <any>roomStatus) as any || localVarFormParams;
        }
        if (roomType !== undefined) {
            localVarFormParams = localVarFormParams.append('RoomType', <any>roomType) as any || localVarFormParams;
        }
        if (statusCallbackEvent !== undefined) {
            localVarFormParams = localVarFormParams.append('StatusCallbackEvent', <any>statusCallbackEvent) as any || localVarFormParams;
        }
        if (timestamp !== undefined) {
            localVarFormParams = localVarFormParams.append('Timestamp', <any>timestamp) as any || localVarFormParams;
        }
        if (participantSid !== undefined) {
            localVarFormParams = localVarFormParams.append('ParticipantSid', <any>participantSid) as any || localVarFormParams;
        }
        if (participantStatus !== undefined) {
            localVarFormParams = localVarFormParams.append('ParticipantStatus', <any>participantStatus) as any || localVarFormParams;
        }
        if (participantDuration !== undefined) {
            localVarFormParams = localVarFormParams.append('ParticipantDuration', <any>participantDuration) as any || localVarFormParams;
        }
        if (participantIdentity !== undefined) {
            localVarFormParams = localVarFormParams.append('ParticipantIdentity', <any>participantIdentity) as any || localVarFormParams;
        }
        if (roomDuration !== undefined) {
            localVarFormParams = localVarFormParams.append('RoomDuration', <any>roomDuration) as any || localVarFormParams;
        }
        if (sequenceNumber !== undefined) {
            localVarFormParams = localVarFormParams.append('SequenceNumber', <any>sequenceNumber) as any || localVarFormParams;
        }
        if (trackSid !== undefined) {
            localVarFormParams = localVarFormParams.append('TrackSid', <any>trackSid) as any || localVarFormParams;
        }
        if (trackKind !== undefined) {
            localVarFormParams = localVarFormParams.append('TrackKind', <any>trackKind) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/twilio/webhook/video/room-status-callback/`;
        return this.httpClient.post<SuccessResponse>(`${this.configuration.basePath}${localVarPath}`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Twilio video log reporting
     * Twilio video log reporting
     * @param appointmentId Appointment ID
     * @param userId User ID
     * @param twilioVideoLogReportRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public twilioVideoLogReport(appointmentId: string, userId: string, twilioVideoLogReportRequest?: TwilioVideoLogReportRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SuccessResponse>;
    public twilioVideoLogReport(appointmentId: string, userId: string, twilioVideoLogReportRequest?: TwilioVideoLogReportRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SuccessResponse>>;
    public twilioVideoLogReport(appointmentId: string, userId: string, twilioVideoLogReportRequest?: TwilioVideoLogReportRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SuccessResponse>>;
    public twilioVideoLogReport(appointmentId: string, userId: string, twilioVideoLogReportRequest?: TwilioVideoLogReportRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (appointmentId === null || appointmentId === undefined) {
            throw new Error('Required parameter appointmentId was null or undefined when calling twilioVideoLogReport.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling twilioVideoLogReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/twilio/log/video/${this.configuration.encodeParam({name: "appointmentId", value: appointmentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        return this.httpClient.post<SuccessResponse>(`${this.configuration.basePath}${localVarPath}`,
            twilioVideoLogReportRequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update appointment details
     * Update appointment details
     * @param companyId Company ID
     * @param appointmentLinkId Appointment Link ID
     * @param appointmentUpdateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppointment(companyId: string, appointmentLinkId: string, appointmentUpdateRequest?: AppointmentUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SuccessResponse>;
    public updateAppointment(companyId: string, appointmentLinkId: string, appointmentUpdateRequest?: AppointmentUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SuccessResponse>>;
    public updateAppointment(companyId: string, appointmentLinkId: string, appointmentUpdateRequest?: AppointmentUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SuccessResponse>>;
    public updateAppointment(companyId: string, appointmentLinkId: string, appointmentUpdateRequest?: AppointmentUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateAppointment.');
        }
        if (appointmentLinkId === null || appointmentLinkId === undefined) {
            throw new Error('Required parameter appointmentLinkId was null or undefined when calling updateAppointment.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/appointment/${this.configuration.encodeParam({name: "appointmentLinkId", value: appointmentLinkId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        return this.httpClient.patch<SuccessResponse>(`${this.configuration.basePath}${localVarPath}`,
            appointmentUpdateRequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update user joined status for any appointment participant
     * Update user joined status for any appointment participant
     * @param companyId Company ID
     * @param appointmentLinkId Appointment Link ID
     * @param userId User ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppointmentUserJoinedStatus(companyId: string, appointmentLinkId: string, userId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateAppointmentUserJoinedStatus(companyId: string, appointmentLinkId: string, userId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateAppointmentUserJoinedStatus(companyId: string, appointmentLinkId: string, userId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateAppointmentUserJoinedStatus(companyId: string, appointmentLinkId: string, userId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateAppointmentUserJoinedStatus.');
        }
        if (appointmentLinkId === null || appointmentLinkId === undefined) {
            throw new Error('Required parameter appointmentLinkId was null or undefined when calling updateAppointmentUserJoinedStatus.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateAppointmentUserJoinedStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/appointment/${this.configuration.encodeParam({name: "appointmentLinkId", value: appointmentLinkId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/joined/`;
        return this.httpClient.patch<any>(`${this.configuration.basePath}${localVarPath}`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
