// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import * as browserImageSize from 'browser-image-size';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'photograph-question',
  templateUrl: './photograph.component.html',
})
export class PhotographComponent implements AfterViewInit, OnChanges, OnDestroy {
  static readonly MAXIMUM_WIDTH_PIXELS = 640;
  static readonly AVAILABLE_WIDTH_PADDING_PIXELS = 20;
  
  @Output() takePhotoEvent = new EventEmitter<any>();
  @Input() scan = false;
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('container') container: ElementRef;
  public permissionDenied = false;
  public isVideoLoading = true;

  async ngAfterViewInit(): Promise<void> {
    const availableWidth = this.container.nativeElement.offsetWidth - PhotographComponent.AVAILABLE_WIDTH_PADDING_PIXELS;
    const width = Math.min(PhotographComponent.MAXIMUM_WIDTH_PIXELS, availableWidth);
    const stream = await this.getCameraStream();
    const video = this.video.nativeElement;
    video.width = width;
    video.srcObject = stream;
    video.onplay = () => { this.isVideoLoading = false; };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  ngOnDestroy(): void {
    const video = this.video?.nativeElement;
    if (video) {
      const stream = video.srcObject as MediaStream;
      stream?.getTracks()?.forEach((track) => track.stop());
      video.srcObject = null;
    }
  }

  takePhoto(): void {
    const video = this.video.nativeElement;
    const canvas = this.canvas.nativeElement;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(
      video,
      0,
      0,
      video.videoWidth,
      video.videoHeight,
    );
    this.question.response.answer = canvas.toDataURL();
    this.takePhotoEvent.emit(canvas);
  }

  retakePhoto(): void {
    this.question.response.answer = null;
  }

  async getCameraStream(): Promise<MediaStream> {
    try {
      const userFacingStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      if (userFacingStream) {
        return userFacingStream;
      } else {
        const anyStream = await navigator.mediaDevices.getUserMedia({ video: true });
        return anyStream;
      }
    } catch (error) {
      if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
        this.permissionDenied = true;
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = async () => {
      const resizedImage = await this.resizeImageFromDataUri(
        reader.result as string,
        500
      );
      console.log('resizedImage', resizedImage);
      this.question.response.answer = resizedImage;
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  } // end func

  async resizeImageFromDataUri(
    dataUri: string,
    maxSize: number
  ): Promise<string> {
    const dimensions = await browserImageSize(dataUri);
    if (dimensions.width > maxSize || dimensions.height > maxSize) {
      const inputImage = new Image();
      await new Promise((resolve) => {
        inputImage.src = dataUri;
        inputImage.onload = resolve;
      }); // Wait until image load completes

      const canvas = document.createElement('canvas');
      canvas.width =
        dimensions.width >= dimensions.height
          ? maxSize
          : Math.floor((dimensions.width * maxSize) / dimensions.height);
      canvas.height =
        dimensions.width < dimensions.height
          ? maxSize
          : Math.floor((dimensions.height * maxSize) / dimensions.width);

      const canvasContext = canvas.getContext('2d');
      canvasContext.imageSmoothingEnabled = true;
      canvasContext.imageSmoothingQuality = 'high';
      canvasContext.drawImage(inputImage, 0, 0, canvas.width, canvas.height);

      // Create a new data URI using canvas
      const outputUri = canvas.toDataURL();

      return outputUri;
    } else {
      // No resize necessary
      return dataUri;
    }
  }
}
