import { Injectable, inject } from '@angular/core';
import {
  DoctorAvailabilityESService,
  DoctorDataSearchESService,
  DoctorFacetSearchData as ServerDoctorFacetSearchData,
  DoctorSearchData as ServerDoctorSearchData,
  SlotData as ServerSlotData,
  DayAvailability as ServerDayAvailability,
  SlotType,
  DoctorMetadata,
  FacetResponseData,
  ServiceData as ServerServiceData,
  Language,
  AppointmentServiceMedium,
  ServiceDataServicesInner as ServerServiceDataServices,
  BookingRestrictions,
  BillingType,
  EarliestAvailabilityByService,
} from '@insig-health/api/doctor-booking-flow-api-v1';
import {
  DoctorManagementService,
  DoctorUpdateRequest as ApiDoctorUpdateRequest,
  SuccessResponse,
} from '@insig-health/api/doctor-api';
import { Province, ProvinceService } from '../province/province.service';
import { lastValueFrom } from 'rxjs';

export enum AggregationName {
  CATEGORY_SERVICE_AGG = 'CATEGORY_SERVICE_AGG',
  SERVICE_TYPE_AGG = 'SERVICE_TYPE_AGG',
  SERVICE_MEDIUM_AGG = 'SERVICE_MEDIUM_AGG',
  LANGUAGE_AGG = 'LANGUAGE_AGG'
}

export interface ExtendedSlotData {
  esSlotId: string;
  doctorId: string;
  startDate: Date;
  endDate: Date;
  duration: number;
  type?: SlotType;
  locationId?: string;
  selected: boolean;
}

export interface DoctorFacetSearchData {
  doctorSearchData: DoctorSearchData[];
  facetResponseData: FacetResponseData[];
}

export interface DoctorSearchData {
  doctorMetadata: DoctorMetadata;
  availabilityData: {
    weekAvailability?: {
      dayAvailability: DayAvailability[];
    };
  } | null;
  earliestAvailableDate: Date;
  service: AppointmentGroup;
}

export interface DayAvailability {
  timeSlots: TimeSlot[];
}

export interface TimeSlot {
  startDate: Date;
  endDate: Date;
}

export interface AppointmentGroup {
  bookingRestrictions: BookingRestrictions | null;
  mediums: Array<AppointmentServiceMedium>;
  appointments: Array<Appointment>;
}

export interface Appointment {
  id: string;
  type: string;
  duration: number;
  mediums: Array<AppointmentServiceMedium>;
  price?: number;
  isPublicServiceCharged: boolean;
  earliestAvailabilityForAppointment: Date | undefined;
}

export const languageNames: { [L in Language]: string } = {
  EN: 'English',
  ES: 'Spanish',
  HI: 'Hindi',
  IT: 'Italian',
  LA: 'Latin',
  KO: 'Korean',
  FR: 'French',
  DE: 'German',
  PT: 'Portuguese',
  RU: 'Russian',
  'NOT DEFINED': '',
};

export enum DoctorFutureAvailabilityFilter {
  EXCLUDE_DOCTORS_WITH_NO_FUTURE_AVAILABILITY = 0,
  INCLUDE_DOCTORS_WITH_NO_FUTURE_AVAILABILITY = 1,
}

export interface DoctorAndFacetSearchParams {
  province: Province;
  billingType: BillingType;
  includeNoFutureAvailability?: DoctorFutureAvailabilityFilter;
  slotData?: 0 | 1;
  companyId?: string;
  doctorId?: string;
  limit?: number;
  appointmentCategory?: string;
  appointmentType?: string;
  queryString?: string;
  planId?: string;
  billingNumber?: string;
}

interface DoctorUpdateRequest {
  firstName?: string;
  lastName?: string;
  title?: string;
  qualifications?: string;
  providerNumber?: string;
  licenseNumber?: string;
  languages?: string;
  specialty?: string;
  cellPhoneNumber?: string;
  clinicPhoneNumber?: string;
  address?: string;
  faxNumber?: string;
  province?: string;
  city?: string;
  lastAcceptedTermsOfUseVersion?: string;
  bookingInstructions?: string;
  image?: string;
  signature?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  private readonly doctorAvailabilityESService = inject(DoctorAvailabilityESService);
  private readonly doctorDataSearchESService = inject(DoctorDataSearchESService);
  private readonly doctorManagementService = inject(DoctorManagementService);
  private readonly provinceService = inject(ProvinceService);
  // threshold where the backend considers a doctor as no longer available
  static readonly EARLIEST_AVAILABLE_DATE_LIMIT = new Date('2080-01-01T01:01:01Z');

  async getDoctorById(doctorId: string): Promise<DoctorSearchData> {
    try {
      const serverDoctorSearchData = await lastValueFrom(this.doctorDataSearchESService.getDoctorSearchData(doctorId));
      return this.convertServerDoctorSearchData(serverDoctorSearchData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDoctorAndFacetData(params: DoctorAndFacetSearchParams): Promise<DoctorFacetSearchData> {
    const filters = this.getFilters(params.appointmentType, params.appointmentCategory);
    try {
      const serverDoctorFacetSearchData$ = this.doctorDataSearchESService.getDoctorDataForParams(
        this.provinceService.convertToServerProvince(params.province),
        params.billingType,
        params.companyId,
        params.doctorId,
        params.planId,
        params.billingNumber,
        params.queryString,
        filters,
        params.includeNoFutureAvailability ?? DoctorFutureAvailabilityFilter.EXCLUDE_DOCTORS_WITH_NO_FUTURE_AVAILABILITY,
        params.slotData ?? 0,
        params.limit ?? 50,
      );
      const serverDoctorFacetSearchData = await lastValueFrom(serverDoctorFacetSearchData$);
      return this.convertServerDoctorFacetSearchData(serverDoctorFacetSearchData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getFilters(appointmentType?: string, appointmentCategory?: string): string | undefined {
    const filters: string[] = [];
    if (appointmentType) {
      filters.push(`serviceType:[${appointmentType}]`);
    }
    if (appointmentCategory) {
      filters.push(`Category:[${appointmentCategory}]`);
    }
    return filters.length === 0 ? undefined : filters.join(',');
  }

  async getDoctorSlotData(startDate: string, endDate: string, slotDuration: number, doctorId: string): Promise<ExtendedSlotData[]> {
    let slotData: ServerSlotData[] = [];

    try {
      slotData = await lastValueFrom(this.doctorAvailabilityESService.getSlots(startDate, endDate, slotDuration, doctorId));
    } catch (error) {
      console.error(error);

      throw error;
    }

    const extendedSlotData: ExtendedSlotData[] = slotData.map((slotDataEntry) => {
      return this.addExtendedSlotDataPropertiesToSlotData(slotDataEntry);
    });

    return extendedSlotData;
  }

  addExtendedSlotDataPropertiesToSlotData(slotData: ServerSlotData): ExtendedSlotData {
    if (slotData.doctorId === undefined) {
      throw new Error('doctorId is undefined in slotData');
    }

    const doctorId = slotData.doctorId;

    return {
      ...slotData,
      doctorId,
      selected: false,
      startDate: new Date(`${slotData.startDateTime}Z`),
      endDate: new Date(`${slotData.endDateTime}Z`),
    };
  }

  getLanguageNameFromAbbreviation(languageAbbreviation: Language): string {
    return languageNames[languageAbbreviation];
  }

  private convertServerDoctorFacetSearchData(serverDoctorFacetSearchData: ServerDoctorFacetSearchData): DoctorFacetSearchData {
    return {
      doctorSearchData: serverDoctorFacetSearchData.doctorSearchData?.map(this.convertServerDoctorSearchData.bind(this)) ?? [],
      facetResponseData: serverDoctorFacetSearchData.facetResponseData ?? [],
    };
  }

  private convertServerDoctorSearchData(serverDoctorSearchData: ServerDoctorSearchData): DoctorSearchData {
    let weekAvailability: { dayAvailability: DayAvailability[] } | undefined;
    const serverWeekAvailability = serverDoctorSearchData.availabilityData?.weekAvailability;
    if (serverWeekAvailability !== undefined) {
      weekAvailability = {
        dayAvailability: serverWeekAvailability.dayAvailability?.map(this.convertServerDayAvailability.bind(this)),
      };
    } else {
      weekAvailability = undefined;
    }

    return {
      doctorMetadata: serverDoctorSearchData.doctorMetadata,
      availabilityData: { weekAvailability },
      earliestAvailableDate: new Date(serverDoctorSearchData.earliestAvailability + 'Z'),
      service: this.convertServerServiceData(serverDoctorSearchData.serviceData),
    };
  }

  private convertServerServiceData(serverServiceData: ServerServiceData): AppointmentGroup {
    const services: ServerServiceDataServices[] = serverServiceData.services ?? [];
    return {
      bookingRestrictions: serverServiceData.bookingRestrictions ?? null,
      mediums: serverServiceData.serviceMedium ?? [],
      appointments: services.map((service) => this.convertServiceDataServices(service, serverServiceData.earliestAvailabilityByService)),
    };
  }

  private convertServiceDataServices(serviceDataServices: ServerServiceDataServices, earliestAvailabilityByService?: EarliestAvailabilityByService[]): Appointment {
    const earliestAvailability = earliestAvailabilityByService?.find((earliestAvailabilityByService) => earliestAvailabilityByService.serviceType === serviceDataServices.serviceType);
    const earliestDateTime = earliestAvailability?.earliestDateTime;
    return {
      id: serviceDataServices.serviceId ?? '',
      type: serviceDataServices.serviceType ?? '',
      duration: serviceDataServices.duration ?? 30,
      mediums: serviceDataServices.serviceMediumList ?? [],
      price: serviceDataServices.price,
      isPublicServiceCharged: serviceDataServices.isServiceCharged ?? false,
      earliestAvailabilityForAppointment: earliestDateTime ? new Date(earliestDateTime + 'Z') : undefined,
    };
  }

  private convertServerDayAvailability(serverDayAvailability: ServerDayAvailability): DayAvailability {
    return {
      timeSlots: serverDayAvailability.slotData?.map(this.convertServerSlotData.bind(this)) ?? [],
    };
  }

  private convertServerSlotData(serverSlotData: ServerSlotData): TimeSlot {
    return {
      startDate: new Date(`${serverSlotData.startDateTime}Z`),
      endDate: new Date(`${serverSlotData.endDateTime}Z`),
    };
  }

  getAppointmentFromListById(appointments: Appointment[], appointmentId: string): Appointment {
    const appointment = appointments.find((appointment) => appointment.id === appointmentId);
    if (appointment === undefined) {
      throw new Error(`Could not find appointment type with ID ${appointmentId}`);
    }
    return appointment;
  }

  async isDoctorAvailable(doctorId: string): Promise<boolean> {
    try {
      const { earliestAvailableDate } = await this.getDoctorById(doctorId);
      return earliestAvailableDate.getTime() < DoctorService.EARLIEST_AVAILABLE_DATE_LIMIT.getTime();
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async updateDoctor(doctorId: string, doctorUpdateRequest: DoctorUpdateRequest): Promise<SuccessResponse> {
    const apiDoctorUpdateRequest = this.getApiDoctorUpdateRequest(doctorUpdateRequest);
    return lastValueFrom(this.doctorManagementService.updateDoctor(doctorId, apiDoctorUpdateRequest));
  }

  getApiDoctorUpdateRequest(doctorUpdateRequest: DoctorUpdateRequest): ApiDoctorUpdateRequest {
    return doctorUpdateRequest;
  }
}
