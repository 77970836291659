// @ts-strict-ignore
import { Component, Input, ViewChild, ElementRef, inject } from '@angular/core';
import firebase from 'firebase/compat/app';
import { FileUploadService } from '../../../../services/fileUpload.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

@Component({
  selector: 'diagram-edit',
  templateUrl: './diagram-edit.component.html',
  providers: [FileUploadService],
})
export class DiagramEditComponent {
  private readonly fileUploadService = inject(FileUploadService);
  private readonly utilitiesService = inject(UtilitiesService);
  @Input() question: any;
  /** The surveyID for saving the image path under. Optional but recommended for organizational purposes. */
  @Input() surveyID?: string;
  @Input() userID: string;
  @ViewChild('container') public container: ElementRef;
  maxWidth = 500;

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const extension = fileName.split('.').pop();

    const path = `user/surveys/${this.userID}/${this.surveyID ||
      this.utilitiesService.getRandomAlphanumerics(32)}/${this.question.id}${extension}`;
    const uploadTask = this.fileUploadService.uploadFile(path, fileData);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        console.log(snapshot);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        this.question.images = downloadURL;
      }
    );
  }
}
